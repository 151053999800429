<template lang="pug">
v-dialog(
    v-model="exportOrderDialog"
    eager
    width="400"
    persistent
  )
    v-card
      v-toolbar(
        color="purple"
        dark
        dense
        flat
      )
        span Export Order
      v-container
        v-row
          v-col(cols="6").ma-0.pb-0
            v-autocomplete(
              dense
              label="Customer"
              v-model="customerId"
              :items="customerIndexList"
              item-value="id"
              item-text="company"
              clearable
            )
              template(v-slot:selection="{ item }")
                span.primary--text {{ item.id }}
                small.blue-grey--text.ml-2 {{ item.company }}
              template(v-slot:item="{ item }")
                span.primary--text {{ item.id }}
                small.blue-grey--text.ml-2 {{ item.company }}
          v-col(cols="6").ma-0.pb-0
            v-autocomplete(
              dense
              label="Revenue Source"
              v-model="revenueSource"
              :items="revenueSourceIndexList"
              :loading="revenueSourceIndexGetting"
              item-value="id"
              item-text="description"
              multiple
              small-chips
              clearable
            )
          v-col(cols="6").ma-0.py-0
            v-autocomplete(
              dense
              label="Month"
              v-model="month"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              clearable
            )
          v-col(cols="6").ma-0.py-0
            v-autocomplete(
              dense
              label="Year"
              v-model="year"
              :items="[2021, 2022]"
              clearable
            )
          v-col(cols="12").ma-0.py-0
            v-autocomplete(
              multiple
              label="Status"
              small-chips
              dense
              v-model="status"
              :items="orderStatusIndexList"
              item-value="id"
              clearable
              item-text="name"
            )
      v-card-action
        v-btn(
          text
          color="green"
          :loading="orderExportV1Getting"
          @click="exportV1"
        )
          span Export
        v-btn(
          text
          color="red"
          @click="exportOrderDialog = false"
        )
          span Close
</template>
<script>
import orderRepository from '@/repositories/order.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [orderExportV1Vars, orderExportV1VarNames] = requestVars({ identifier: 'order-export-v1', hasData: false })

const orderExportV1Handler = new VueRequestHandler(null, orderExportV1VarNames, { delay: 1500 })

const inputVars = () => ({
  customerId: null,
  revenueSource: [],
  month: null,
  year: null,
  status: [],
})

export default {
  name: 'ExportOrder',
  props: ['value', 'customerIndexList', 'orderStatusIndexList', 'revenueSourceIndexList'],
  data () {
    return {
      ...inputVars(),
      ...orderExportV1Vars,
    }
  },
  computed: {
    exportOrderDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    exportV1 () {
      const handler = orderExportV1Handler
      const repository = orderRepository.exportV1
      const params = this.getData()
      handler.setVue(this)
      handler.execute(repository, [params], ({ data }) => {
        const blob = new Blob([data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `orders_${new Date().getTime()}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      }, alert)
    },
    getData () {
      return Object.keys(inputVars())
        .reduce((result, key) => {
          const data = this[key]
          const arrayEmpty = a => !a.length && Array.isArray(a)
          if (!data || arrayEmpty(data)) return result
          if (Array.isArray(this[key])) {
            result[key] = data.join(',')
            return result
          }
          result[key] = data
          return result
        }, {})
    },
  },
}
</script>